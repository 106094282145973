// Customizable Area Start
import React from "react"; 
import { Input, Button } from '@builder/component-library';
import { Box, Typography, styled, Checkbox } from "@mui/material";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckIcon from '@mui/icons-material/Check';
import RemoveIcon from "@mui/icons-material/Remove";
import i18n from "../../../components/src/i18nextConfig/i18n";
import NewPasswordController, {
  Props,
} from "./NewPasswordController.web";
import LoadingSpinner from "../../../components/src/LoadingSpinner";
import { LoginHelper } from "../../../components/src/LoginHelper";

// Customizable Area End
export default class NewPassword extends NewPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  setLoginHandler = (handler: (token: string) => void) => {
    this.loginHandler = handler;
  };
  renderErrorMessage = () => {
    return (
      this.state.errorStatus &&
        <ShowMessageAlert>
          <ErrorMessage>{i18n.t(this.state.errorMessage)}</ErrorMessage>
        </ShowMessageAlert> 
    )
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { t } = i18n; 
    return (
      <>
       <LoginHelper onLoginHandlerReady={this.setLoginHandler} />
      <Main>
          {this.state.loading && <LoadingSpinner />}
          <Body>
            <HeadContainer>
              <Header variant="h4" >
                { t (this.newTitle) }
              </Header>
              
              <SubHeader variant="h1">
                {
                  t(this.createText)
                }
              </SubHeader>
                {this.renderErrorMessage()}
            </HeadContainer>
            <FormWrapper>
              <FormWrapperExcluded>
              <InputWrapper>
                <Label>{t(this.newTitle)}</Label>
                <Input
                  data-test-id="txtInputPassword"
                  secureTextEntry={this.state.enablePasswordField}
                  placeholder={t(this.newTitle)}
                  value={this.state.password}
                  onChangeText={(text) => {
                    this.setPassword(text);

                  }}
                  rightIcon={
                    <Button
                      style={{ backgroundColor: "transparent" }}
                      data-test-id="btnPasswordShowHide"
                      aria-label="toggle password visibility"
                      onPress={this.handleClickShowPassword}
                      icon={this.state.enablePasswordField ? (
                        <VisibilityIcon style={{   color:"#94A3B8"}}/>
                      ) : (
                        < VisibilityOffIcon style={{ color:"#94A3B8" }}/>
                      )}
                    />
                  } 
                />
              </InputWrapper>

              <CustomCheckbox>
              <Box display="flex" alignItems="center">
                  <Checkbox
                  checked={this.state.checkedLetter}
                  inputProps={{ 'aria-label': 'controlled' }}
                  icon={
                    this.state.password.length > 0 && !this.state.checkedLetter ? (
                      <RemoveIcon sx={{ color: '#ffffff' }} /> 
                    ) : undefined 
                  }
                  checkedIcon={
                    <CheckIcon sx={{ color: '#ffffff',backgroundColor:'#059669',borderRadius:'4px' }} /> 
                  }
                  sx={
                    this.state.password.length > 0 && !this.state.checkedLetter
                      ? redMinusStyle
                      : {}
                  }
                  />

                  <CheckBoxText>
                     { t(this.passwordCondition1) } 
                  </CheckBoxText>
                </Box>
                <Box display="flex" alignItems="center">
                  <Checkbox
                    data-test-id={"btnRememberMe"}
                    style={{ margin: 1 }}
                    title=""
                    checked={this.state.checkedLength}
                    icon={
                      this.state.password.length > 0 && !this.state.checkedLength ? (
                        <RemoveIcon sx={{ color: '#ffffff' }} /> 
                      ) : undefined 
                    }
                    checkedIcon={
                      <CheckIcon sx={{ color: '#ffffff',backgroundColor:'#059669',borderRadius:'4px' }} /> 
                    }
                    sx={
                      this.state.password.length > 0 && !this.state.checkedLength
                        ? redMinusStyle
                        : {}
                    }
                
                  />
                  <CheckBoxText> 
                     { t(this.passwordCondition2)}
                  </CheckBoxText>
                </Box>
                <Box display="flex" alignItems="center">
                <Checkbox
                    data-test-id={"btnRememberMe"}
                    title=""
                    style={{ margin: 1 }}
                    checked={this.state.password.length > 0 && this.state.checkedDigit}
                    icon={
                      this.state.password.length > 0 && !this.state.checkedDigit ? (
                        <RemoveIcon sx={{ color: '#ffffff' }} /> 
                      ) : undefined 
                    }
                    checkedIcon={
                      <CheckIcon sx={{ color: '#ffffff',backgroundColor:'#059669',borderRadius:'4px' }} /> 
                    }
                    sx={
                      this.state.password.length > 0 && !this.state.checkedDigit
                        ? redMinusStyle
                        : {}
                    }
                  />
                  <CheckBoxText> { t(this.passwordCondition3)}</CheckBoxText>
                </Box>
                </CustomCheckbox>
                </FormWrapperExcluded>
              <ButtonsWrapper>
                <Button
                  data-test-id={"btnEmailLogIn"}
                  style={{ backgroundColor: "#00496B" }}
                  text= {t(this.btnText) } 
                  onPress={() => this.createNewPassword()}
                />

                </ButtonsWrapper>
            </FormWrapper>
          </Body>
        </Main>
      </> 
    );
    // Customizable Area End
  }

}
// Customizable Area Start

const redMinusStyle = {
  '& .MuiSvgIcon-root': {
    backgroundColor: '#E4022E',
    borderRadius: '4px',
  },
};

const Main = styled(Box)(({ theme }) => ({
  padding: "20px",
  display: "flex",
  alignItems: "center",
  background: "#E9F6FF",
  justifyContent: "center",
  height:"calc(100vh - 130px)",
  fontFamily: "Roboto",
  [theme.breakpoints.down('sm')]: {
    padding: "10px",
  },
}));
  
  const Body = styled(Box)(({ theme }) => ({
    background: "#FFFFFF",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "30px 10px",
    borderRadius: "10px",
    width: "100%",
    maxWidth: "477px",
    justifyContent: "space-between",
    height: "65vh",
    [theme.breakpoints.down('sm')]: {
      padding: "20px",
      height: "auto",
      maxWidth: "100%",
      margin: "5vh 0 0 0"
    },
  }));
  
  const HeadContainer = styled(Box)(({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "10px", 
  }));
  
  const Header = styled(Typography)(({ theme }) => ({
    fontSize: "28px",
    color: "#053348",
    fontWeight: 800,
    [theme.breakpoints.down('sm')]: {
      fontSize: "24px",
    },
  }));
  
  const SubHeader = styled(Typography)(({ theme }) => ({
    fontSize: "17px",
    fontWeight: 500,
    color: "#053348",
    textAlign: "center",
    [theme.breakpoints.down('sm')]: {
      fontSize: "14px",
    },
  }));
  
  const ShowMessageAlert = styled(Box)(({ theme }) => ({
    background: "#FEE2E2",
    borderLeft: "4px solid #E4022E",
    width: "96%", 
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "4px",
    color: "#E4022E",
    opacity: 1,
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },
  }));
  
  const ErrorMessage = styled(Typography)(({
    fontSize: "12px",
    fontWeight: 400,
    textAlign: "center",
    padding: "10px",
  }));
  
  const FormWrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    width: "100%",
    paddingTop: "20px",
    flexDirection: "column",
    maxWidth: "391px",
    [theme.breakpoints.down('sm')]: {
      paddingTop: "15px",
      maxWidth: "100%",
    },
  }));
  
  const InputWrapper = styled(Box)(({ theme }) => ({
    flex: 1,
    padding: "10px 20px",
    flexDirection: "column",
    maxWidth: "391px",
    [theme.breakpoints.down('sm')]: {
      padding: "10px",
      maxWidth: "100%",
    },
  }));
  
  const CustomCheckbox = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    [theme.breakpoints.down('sm')]: {
      padding: "8px",
    },
  }));
  
  const CheckBoxText = styled(Typography)(({ theme }) => ({
    fontSize: "12px",
    color: "#053348",
    [theme.breakpoints.down('sm')]: {
      fontSize: "10px",
    },
  }));
  
  const Label = styled(Typography)(({ theme }) => ({
    fontSize: "16px",
    fontWeight: 500,
    color: "#053348",
    marginBottom: "5px",
    [theme.breakpoints.down('sm')]: {
      fontSize: "14px",
    },
  }));
  
  const ButtonsWrapper = styled(Box)(({ theme }) => ({
    flex: 1,
    padding: "20px",
    [theme.breakpoints.down('sm')]: {
      padding: "15px",
    },
  }));
  
  const FormWrapperExcluded = styled(Box)(({ theme }) => ({
    padding: "5vh 0px",
    [theme.breakpoints.down('sm')]: {
      padding: "3vh 0px",
    },
  }));
// Customizable Area End

