// useLoginHandler.ts
import { useAuth } from './AuthProvider';
import { useNavigate } from 'react-router-dom';

export const useLoginHandler = () => {
  const { setIsLoggedIn } = useAuth();
  const navigate = useNavigate();

  return (token: string) => {
    localStorage.setItem('token', token);
    setIsLoggedIn(true);
    // navigate('/Dashboard');
  };
};
