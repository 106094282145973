import React, { createContext, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

type NavigateFunction = (to: string, options?: { replace?: boolean; state?: any }) => void;

// Create the context with NavigateFunction type
export const NavigationContext = createContext<NavigateFunction | undefined>(undefined);

interface NavigationProviderProps {
  children: ReactNode;
}

export const NavigationProvider: React.FC<NavigationProviderProps> = ({ children }) => {
  const navigate = useNavigate();

  return (
    <NavigationContext.Provider value={navigate}>
      {children}
    </NavigationContext.Provider>
  );
};