// Customizable Area Start
import React from "react";
import { Box, Typography, styled} from "@mui/material";

import ForgotPasswordController, {
  Props,
} from "./ForgotPasswordController.web";
import i18n from "../../../components/src/i18nextConfig/i18n";
import LoadingSpinner from "../../../components/src/LoadingSpinner";

// Customizable Area End
export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  emailInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value as string
    this.setState({emailValue:email.trim()});
    this.isButtonEnabled = email.length > 0;
    this.setState({isError:false})
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    const {t} = i18n;
    return (
      <>
      <Main>
        {this.state.loading && <LoadingSpinner/>}
        <Body>
          <HeaderContainer>
            <HeadText data-test-id='headText'>{t(this.resetText)}</HeadText>
            <SubHeadText>{t(this.verifyText)}</SubHeadText>
          </HeaderContainer>
          <FormWrapper>
              <InputWrapper>
                <Label>{t(this.emailLabelText)}</Label>
                <CustomInput
                  data-test-id="txtInputEmail"
                  placeholder={t("Your "+this.emailLabelText)}
                  value={this.state.emailValue}
                  onChange={this.emailInputHandler}
                  className={this.state.isError? "invalid" : ""}
                />
                {this.state.isError && <AlertMessage>{t(this.state.errorMessage)}</AlertMessage>}
              </InputWrapper>
          </FormWrapper>
          <BottomContainer>
            <VerifyButton
            className={this.isButtonEnabled ? "enabled" : "disabled"}
            disabled={!this.isButtonEnabled}
            data-test-id="verifyButton"
            onClick={this.sendMailtoVerify}
            >{t("Send")}</VerifyButton>
            </BottomContainer>
        </Body>
      </Main>
      </>
      
    );
    // Customizable Area End
  }

}
// Customizable Area Start
const Main = styled(Box)(({ theme }) => ({
  padding: "20px",
  display: "flex",
  alignItems: "center",
  background: "#E9F6FF",
  justifyContent: "center",
  fontFamily: "Roboto",
  maxWidth: "100%",
  height: "calc(100vh - 130px)",
  [theme.breakpoints.down('md')]: {
    padding: "15px",
  },
  [theme.breakpoints.down('sm')]: {
    padding: "10px",
    flexDirection: "column",
  }
}));

const Body = styled(Box)(({ theme }) => ({
  background: "#FFFFFF",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "30px 0px",
  borderRadius: "10px",
  width: "90%",
  maxWidth: "477px",
  height: "70%",
  maxHeight: "100%",
  justifyContent: "space-between",
  [theme.breakpoints.up(1920)]: {
  height: "45%", 
  },
  [theme.breakpoints.down('md')]: {
    padding: "20px 0px",
    maxWidth: "400px",
  },
  [theme.breakpoints.down('sm')]: {
    maxWidth: "100%",
    padding: "15px",
  },
}));

const InputWrapper = styled(Box)(({ theme }) => ({
  flex: 1,
  padding: "10px 0px",
  flexDirection: "column",
  marginBottom: "10px",
  fontSize: "14px",
  [theme.breakpoints.down('sm')]: {
    fontSize: "12px",
    padding: "8px 0px",
  },
}));

const BottomContainer = styled("div")(({ theme }) => ({
  textAlign: "center",
  width: "100%",
  marginTop: "20px",
  [theme.breakpoints.down('sm')]: {
    marginTop: "15px",
  }
}));

const HeaderContainer = styled("div")(({ theme }) => ({
  textAlign: "center",
  marginBottom: "20px",
  gap: "15px",
  maxWidth: "391px",
  [theme.breakpoints.down('sm')]: {
    marginBottom: "15px",
    gap: "10px",
  }
}));

const HeadText = styled(Typography)(({ theme }) => ({
  fontSize: "28px",
  marginBottom: "15px",
  color: "#053348",
  fontWeight: 800,
  [theme.breakpoints.down('md')]: {
    fontSize: "24px",
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: "20px",
    marginBottom: "10px",
  },
}));

const SubHeadText = styled(Typography)(({ theme }) => ({
  fontSize: "18px",
  fontWeight: 500,
  color: "#053348",
  padding: "10px 0px",
  [theme.breakpoints.down('md')]: {
    fontSize: "16px",
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: "14px",
  },
}));

const FormWrapper = styled(Box)(({ theme }) => ({
  maxWidth: "391px",
  display: "flex",
  width: "90%",
  position: "relative",
  top :"-5vh",
  flexDirection: "column",
  [theme.breakpoints.down('sm')]: {
    paddingTop: "15px",
  }
}));

const Label = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  color: "#053348",
  marginBottom: "5px",
  [theme.breakpoints.down('sm')]: {
    fontSize: "12px",
  }
}));

const CustomInput = styled('input')(({ theme }) => ({
  padding: '18px 71px 18px 8px',
  border: "1px solid #CBD5E1",
  width: '100%',
  height: '56px',
  borderRadius: '8px',
  opacity: 1,
  '&.invalid': {
    border: "1px solid #E4022E",
  },
  [theme.breakpoints.down('sm')]: {
    padding: '14px 50px 14px 8px',
    height: '48px',
  }
}));

const AlertMessage = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "16.8px",
  textAlign: "left",
  padding: "5px",
  color: "#E4032E",
  [theme.breakpoints.down('sm')]: {
    fontSize: "12px",
  }
}));

const VerifyButton = styled("button")(({ theme }) => ({
  background: "#A0AEC0",
  border: "none",
  color: "#ffffff",
  fontWeight: "700",
  maxWidth: "391px",
  width: "90%",
  padding: "14px 80px",
  fontSize: "14px",
  borderRadius: "5px",
  cursor: "not-allowed",
  opacity: 0.6,
  "&.enabled": {
    background: "#00496B",
    cursor: "pointer",
    opacity: 1,
  },
  [theme.breakpoints.down('sm')]: {
    padding: "14px 40px",
    fontSize: "12px",
  }
}));
// Customizable Area End

