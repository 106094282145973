// index.js - WEB
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import registerServiceWorker from '../../components/src/registerServiceWorker';
import "../../components/src/i18nextConfig/i18n"
import { AuthProvider, } from '../../components/src/AuthProvider';
ReactDOM.render(
  <AuthProvider>
  <Router>
    <App />
  </Router>
  </AuthProvider>,
  document.getElementById('root')
);
registerServiceWorker();
