// Customizable Area Start
import React, { createContext, useContext, useState, ReactNode } from 'react';

interface SearchContextType {
  searchValue: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  searchList: string[];
  setSearchList: React.Dispatch<React.SetStateAction<string[]>>;
  searchCount: number;
  setSearchCount: React.Dispatch<React.SetStateAction<number>>;
  selectedValue: string;
  setSelectedValue:React.Dispatch<React.SetStateAction<string>>;
  selectedCountValue: number;
  setSelectedCountValue: React.Dispatch<React.SetStateAction<number>>;
  isResultEmpty : boolean;
  setIsResultEmpty : React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  searchPlaceHolder : string;
  setSearchPlaceHolder : React.Dispatch<React.SetStateAction<string>>;
}

const SearchContext = createContext<SearchContextType | undefined>(undefined);

interface SearchProviderProps {
  children: ReactNode;
}

export const SearchProvider: React.FC<SearchProviderProps> = ({ children }) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchList, setSearchList] = useState<string[]>([]);
  const [searchCount, setSearchCount] = useState<number>(0);
  const [selectedValue, setSelectedValue] = useState<string>('');
  const [selectedCountValue, setSelectedCountValue] = useState<number>(0);
  const [isResultEmpty, setIsResultEmpty] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchPlaceHolder ,setSearchPlaceHolder] = useState("Search");
  return (
    <SearchContext.Provider value={{ searchValue, setSearchValue, searchList, setSearchList,searchCount,setSearchCount,selectedValue,setSelectedValue,selectedCountValue,setSelectedCountValue,isResultEmpty,setIsResultEmpty,loading,setLoading,setSearchPlaceHolder,searchPlaceHolder }}>
      {children}
    </SearchContext.Provider>
  );
};

export const useSearch = (): SearchContextType => {
  const context = useContext(SearchContext);
  if (!context) {
    throw new Error('useSearch must be used within a SearchProvider');
  }
  return context;
};

export const withSearch = (WrappedComponent: React.ComponentType<any>) => {
  return function WithSearchComponent(props: any) {
    const { searchValue, searchList, setSearchValue,setSearchList,searchCount,setSearchCount,selectedValue,selectedCountValue,setSelectedCountValue,setSelectedValue ,setIsResultEmpty,isResultEmpty,setLoading,loading,searchPlaceHolder,setSearchPlaceHolder} = useSearch();
    return (
      <WrappedComponent {...props} searchValue={searchValue} setSearchValue={setSearchValue} searchList={searchList} setSearchList={setSearchList} setSearchCount={setSearchCount} searchCount={searchCount} selectedValue={selectedValue} selectedCountValue={selectedCountValue} setSelectedCountValue={setSelectedCountValue} setSelectedValue = {setSelectedValue} isResultEmpty={isResultEmpty} setIsResultEmpty={setIsResultEmpty} loading={loading} setLoading={setLoading} searchPlaceHolder={searchPlaceHolder} setSearchPlaceHolder={setSearchPlaceHolder}/>
    );
  };
};
// Customizable Area End
