// LoginHelper.tsx
import React from 'react';
import { useLoginHandler } from './useLoginHandler';

interface LoginHelperProps {
  onLoginHandlerReady: (loginHandler: (token: string) => void) => void;
}

export const LoginHelper: React.FC<LoginHelperProps> = ({ onLoginHandlerReady }) => {
  const loginHandler = useLoginHandler();

  React.useEffect(() => {
    onLoginHandlerReady(loginHandler); // Pass the login handler to the class component
  }, [loginHandler, onLoginHandlerReady]);

  return null;
};
