// App.js - WEB
import React, { Component ,useRef,useEffect} from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';
import { Outlet, } from 'react-router-dom';
import { WebRoutesGenerator } from '../../components/src/NavigationRouteWrapper/Web';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import EmailAccountLoginBlock from '../../blocks/email-account-login/src/EmailAccountLoginBlock.web';
import EmailAccountRegistrationBlock from '../../blocks/email-account-registration/src/EmailAccountRegistration.web';
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import Cfrelatetemplatestosapuser from "../../blocks/cfrelatetemplatestosapuser/src/Cfrelatetemplatestosapuser";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import Cftriggeraccountinvitefromsap from "../../blocks/cftriggeraccountinvitefromsap/src/Cftriggeraccountinvitefromsap";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword.web";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword.web";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Cfdashboarddatafetch0 from "../../blocks/cfdashboarddatafetch0/src/Cfdashboarddatafetch0";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Cfsubmitformtowebhook from "../../blocks/cfsubmitformtowebhook/src/Cfsubmitformtowebhook";
import Cfconfigureemailtemplateswithsmtpdetails from "../../blocks/cfconfigureemailtemplateswithsmtpdetails/src/Cfconfigureemailtemplateswithsmtpdetails";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import Cfemailrichtexteditor from "../../blocks/cfemailrichtexteditor/src/Cfemailrichtexteditor";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import Settings2 from "../../blocks/settings2/src/Settings2";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth.web";
import Cfgetnewaccountsfromsap from "../../blocks/cfgetnewaccountsfromsap/src/Cfgetnewaccountsfromsap";
import Cfautologout0 from "../../blocks/cfautologout0/src/Cfautologout0";
import Location from "../../blocks/location/src/Location";
import Analytics from "../../blocks/analytics/src/Analytics";
import LanguageOptions from "../../blocks/languageoptions/src/LanguageOptions";
import LanguageSupport from "../../blocks/languagesupport/src/LanguageSupport";
import Cfaccountdeletionpushedfromsap0 from "../../blocks/cfaccountdeletionpushedfromsap0/src/Cfaccountdeletionpushedfromsap0";
import Cfaccountdatafetchfromsap0 from "../../blocks/cfaccountdatafetchfromsap0/src/Cfaccountdatafetchfromsap0";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Cfaccountremovalrequestfromsap from "../../blocks/cfaccountremovalrequestfromsap/src/Cfaccountremovalrequestfromsap";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import {AppHeader} from "../../components/src/AppHeader.web"
import CreateNewPassword from "../../blocks/forgot-password/src/NewPassword.web"
import Dashboard from "../../blocks/dashboard/src/Dashboard.web";
import i18n from 'i18next';
import {SearchProvider} from "../../components/src/SearchContext"
import {PrivateRoutes,NewPasswordRoute,PublicRoute} from "../../components/src/PrivateRoutes"
import { Navigate,useNavigate } from 'react-router-dom';
import { useAuth } from '../../components/src/AuthProvider';
const routeMap = {
  Home: {
    component: () => {
      const isAuthenticated = Boolean(localStorage.getItem('token'));
      return (
        <Navigate to={isAuthenticated ? '/Dashboard' : '/login'} />
      );
    },
    path: '/',
  },
ForgotPassword:{
  component:() => (
    <PublicRoute>
      <ForgotPassword/>
    </PublicRoute>
  ),
  path:"/ForgotPassword"
},
NewPassword:{
 component:() => (
  <PublicRoute>
    <NewPasswordRoute><NewPassword/></NewPasswordRoute>
  </PublicRoute>
 ),
path:"/NewPassword"
},

OTPInputAuth:{
 component: () => (
  <PublicRoute>
    <OTPInputAuth/>
  </PublicRoute>
 ),
path:"/OTPInputAuth"
},

  ActivationPage: {
    component: () => (
      <PublicRoute>
        <EmailAccountRegistrationBlock/>
      </PublicRoute>
    ),
    path: '/activate',private: false
  },
  Dashboard : {
    component:  () => (
      <PrivateRoutes>
          <Dashboard />
      </PrivateRoutes>
  ),
    path: '/Dashboard',
  },
  CreateNewPassword: {
    component :() => (
      <PublicRoute>
       <NewPasswordRoute><CreateNewPassword/></NewPasswordRoute>
      </PublicRoute>
    ),
    path: "/CreateNewPassword",
  },
  Login:{
    component: () => (
      <PublicRoute>
        <EmailAccountLoginBlock/>
      </PublicRoute>
    ),
   path:"/login",
   exact: true,
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

const defaultAnalytics = firebaseAPI.analytics();
defaultAnalytics.logEvent('APP_Loaded');

function App() {
  const {setIsLoggedIn} = useAuth();
  const timerRef = useRef(null);
  const navigate = useNavigate()
  const routePathName = ["/login","/dashboard","/activate","/forgotpassword","/createnewpassword","/otpinputauth","/"];
  const resetTimer = () => {
    if (timerRef.current) clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      handleLogout();
    }, 30 * 60 * 1000); 
  };

  const handleLogout = () => {
    const email = localStorage.getItem("email")||"";
    setIsLoggedIn(false);
    localStorage.clear();
    navigate(`/login?email=${email}&status=inActive`)
  };
  const chooseCorrectLanguage = (urlLanguage ,urlEmail ) => {
    const storedEmail = localStorage.getItem("email") || "";
    const storedLanguage = localStorage.getItem('language') || 'en';
    const languageMap = {
      german: 'de',
      english: 'en',
      dutch: 'nl'
    };
    const languageCode = languageMap[urlLanguage.toLowerCase()] || 'en';
    if (!storedEmail || urlEmail !== storedEmail) {
      localStorage.setItem("language",languageCode);
      return languageCode;
    }
    localStorage.setItem("language",storedLanguage);
    return storedLanguage;
  };
  const handleLanguageConversion = () => {
    const params = new URLSearchParams(window.location.search);
    const email = params.get("email") || "";
    const urlLanguage = params.get('language') || "";
    const languageMap = {
      german: 'de',
      english: 'en',
      dutch: 'nl'
    }; 
    let languageCode = languageMap[urlLanguage.toLowerCase()] || 'en';
    let pathName = window.location.pathname;
    pathName = pathName.toLowerCase();
    if(pathName === "/activate"){
      languageCode = chooseCorrectLanguage(urlLanguage,email);
    }
    let language =  localStorage.getItem("language") ||languageCode|| 'en';
    language = language.toLowerCase();
    localStorage.setItem("language",language);
    i18n.changeLanguage(language);
  }
  useEffect(() => {
    const token = localStorage.getItem("token");
    handleLanguageConversion()
    if (token) {
      resetTimer();
      const events = ['mousemove', 'mousedown', 'click', 'scroll', 'keypress'];
      events.forEach((event) => window.addEventListener(event, resetTimer));
      return () => {
        events.forEach((event) => window.removeEventListener(event, resetTimer));
        if (timerRef.current) clearTimeout(timerRef.current);
      };
    }
  }, []);
  const appPathname = window.location.pathname;
  if(!routePathName.includes(appPathname.toLowerCase())){
    return <Navigate to="/" />
  }
  return (
   <BuilderProvider>
      <View>
        <SearchProvider>
          <AppHeader/>
          <WebRoutesGenerator routeMap={routeMap} />
          <Outlet />
          <AlertBlock />
        </SearchProvider>
      </View>
    </BuilderProvider>
  );
}

export default App;