import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";

// Customizable Area Start
import MessageEnum, {
    getName,
  } from "../../../framework/src/Messages/MessageEnum";
  import { runEngine } from "../../../framework/src/RunEngine";
  import { IBlock } from "../../../framework/src/IBlock";
  import { NavigationContext } from '../../../components/src/NavigationProvider';
  import { NavigateFunction } from 'react-router-dom';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  enablePasswordField: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  title: string;
  loginHeader: string;
  emailLabel: string;
  resetPassword: string;
  btnTxtLogin: string;
  emailError: boolean,
  passwordError: boolean,
  emailErrorMessage: string,
  passwordErrorMessage: string,
  inActiveStatus : boolean,
  loading: boolean
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  static contextType = NavigationContext;
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  passwordLabel =   configJSON.newPass + configJSON.word;
  placeHolderPassword=configJSON.your + configJSON.pass + configJSON.word;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
        getName(MessageEnum.CountryCodeMessage),
        getName(MessageEnum.RestAPIResponceMessage),
        getName(MessageEnum.ReciveUserCredentials),
      ];
    this.state = {
        email: "",
        password: "",
        enablePasswordField: true,
        placeHolderEmail: configJSON.webPlaceHolderemail ,
        placeHolderPassword: configJSON.placeHolderPassword,
        title: configJSON.title,
        loginHeader: configJSON.loginHeader,
        emailLabel: configJSON.emailLabel,
        resetPassword: configJSON.resetPassword,
        btnTxtLogin: configJSON.btnTxtLogin,
        emailError: false,
        passwordError: false,
        emailErrorMessage: "",
        passwordErrorMessage: "",
        inActiveStatus : false,
        loading: false,
      };
      this.emailReg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      this.labelTitle = configJSON.labelTitle;

      runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getInactiveStatus()
    // Customizable Area End
  }

  // Customizable Area Start
  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };
  setEmail = (text: string) => {
    this.setState({
      email: text,
      emailError:false,
      passwordError:false
    });
  };
  setPassword = (text: string) => {
    this.setState({
      password: text,
      passwordError:false,
      emailError:false
    });
  };


  doEmailLogIn() :boolean{
    if (
      this.state.email === null ||
      this.state.email.length === 0 ||
      !this.emailReg.test(this.state.email)
    ) {
      this.setState({
        emailError: true,
        emailErrorMessage:configJSON.emailErrorMessage
      })
      return false;
    }
    this.setState({loading:true});
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email: this.state.email,
      password: this.state.password,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.webLoginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  saveLoggedInUserData(responseJson: any) {
    const navigate:NavigateFunction = this.context as NavigateFunction;
    if (responseJson && responseJson.message) {
      localStorage.setItem("email",this.state.email)
      localStorage.setItem("phoneNumber", responseJson.meta.account.phone_number);
      localStorage.removeItem("remainingTime")
      navigate("/OTPInputAuth");
      } else {
        this.handleLoginFailure(responseJson);
      }
  }

  
  handleLoginFailure(responseJson?: any) {
    if (responseJson?.errors && Array.isArray(responseJson.errors) && responseJson.errors.length > 0) {
      const message = responseJson.errors[0]?.failed_login || "";
      if(message.toLowerCase().includes("email")){
        this.setState({ emailErrorMessage: message,emailError:true });
      }
      else{
        this.setState({ passwordErrorMessage: message,passwordError:true });
      }
    } 
  }


  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  private isUserCredentialsMessage(message: Message): boolean {
    return getName(MessageEnum.ReciveUserCredentials) === message.id;
  }
  
  private isRestAPIResponseMessage(message: Message): boolean {
    return getName(MessageEnum.RestAPIResponceMessage) === message.id;
  }
  
  private handleUserCredentials(message: Message): void {
    const userName = message.getData(getName(MessageEnum.LoginUserName));
    const password = message.getData(getName(MessageEnum.LoginPassword));
  
    if (userName && password) {
      this.setState({
        email: userName,
        password: password,
      });
    }
  }
  
  private handleRestAPIResponse(message: Message): void {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

  
    if (!apiRequestCallId) return;
  
    if (apiRequestCallId === this.apiEmailLoginCallId) {
      this.processEmailLoginResponse(responseJson);
    }
  }
  
   processEmailLoginResponse(responseJson: any): void {
    if (responseJson) {
      this.saveLoggedInUserData(responseJson);
    } else {
      this.sendLoginFailMessage();
    }
  }

  redirectToForgotPage = () => {
    const navigate :NavigateFunction =this.context as NavigateFunction ;
    navigate("/ForgotPassword")
  }
  getInactiveStatus = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const inactiveStatus = urlParams.get('status');
    const email = urlParams.get("email")||"";
    this.setState({email});
    if(inactiveStatus  === 'inActive'){
      this.setState({
        inActiveStatus:true,
      })
    }
  }
  handleCloseDialog = () => {
    const navigate:NavigateFunction = this.context as NavigateFunction 
    this.setState({
      inActiveStatus: false,
    });
    navigate(`/login?email=${this.state.email}`);
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.setState({
      loading: false
    })
      if (this.isUserCredentialsMessage(message)) {
        this.handleUserCredentials(message);
      } else if (this.isRestAPIResponseMessage(message)) {
        this.handleRestAPIResponse(message);
      }
    // Customizable Area End
  }
  
}
