// Customizable Area Start
import React, { useEffect, useState ,useRef} from 'react'
import { Box, styled, Select, AppBar, Toolbar, Button, SelectChangeEvent, MenuItem } from "@mui/material";
import { navLogo, English, german, dutch, logoutIcon } from './assets';
import CustomLogoutDialog from "./CustomLogoutDialog.web";
import SearchIcon from '@mui/icons-material/Search';
import { useSearch } from '../../components/src/SearchContext';
import i18n from './i18nextConfig/i18n';
import {useAuth} from "../../components/src/AuthProvider"
import {useNavigate} from "react-router-dom"
// Customizable Area End
const languageOptions = [
  { value: 'en', label: 'English', image: English, alt: "en" },
  { value: 'de', label: 'German', image: german, alt: "de" },
  { value: 'nl', label: 'Dutch', image: dutch, alt: "nl" },
];

export function AppHeader() {
  const navigate = useNavigate();
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [searchInputValue,setSearchInputValue] = useState("");
  const { isLoggedIn ,setIsLoggedIn} = useAuth();
  const [language, setlanguage] = useState('en');
  const [isDialogOpen, setDialogOpen] = useState(false);
  const { setSearchList,setSearchValue, searchList,setSearchCount,searchCount,setSelectedCountValue,setSelectedValue,selectedCountValue,selectedValue ,isResultEmpty,loading,setLoading,setIsResultEmpty,searchPlaceHolder,setSearchPlaceHolder} = useSearch();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const languageInputHandler = (e: SelectChangeEvent<unknown>) => {
    const selectedValue = e.target.value as string;
    setlanguage(selectedValue);
    localStorage.setItem("language", selectedValue.toLowerCase());
    window.location.reload();
  };
  const handleLogoutClick = () => {
    setDialogOpen(true);
  };

  const handleConfirmLogout = () => {
    setDialogOpen(false);
    const language = localStorage.getItem("language") || "en";
    localStorage.clear();
    localStorage.setItem("language", language);
    setIsLoggedIn(false);
    setSearchInputValue("");
    setSearchPlaceHolder("Search");
    setIsResultEmpty(false)
    setSearchList([]);
    navigate("/login")
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  // Customizable Area Start
  function getTextBeforeParenthesis(input :string) {
    const firstStart = input.indexOf("(");
    const firstEnd = input.indexOf(")", firstStart);
    const secondStart = input.indexOf("(", firstStart + 1);
    const secondEnd = input.indexOf(")", secondStart);

    if (secondStart !== -1 && secondEnd !== -1) {
        return input.slice(0, secondStart).trim() + input.slice(secondEnd + 1).trim();
    } else if (firstStart !== -1 && firstEnd !== -1) {
        return input.slice(0, firstStart).trim() + input.slice(firstEnd + 1).trim();
    } else {
        return input.trim();
    }
}

function getTextWithParenthesis(input :string) {
  const firstStart = input.indexOf("(");
  const firstEnd = input.indexOf(")", firstStart);
  const secondStart = input.indexOf("(", firstStart + 1);
  const secondEnd = input.indexOf(")", secondStart);

  if (secondStart !== -1 && secondEnd !== -1) {
      return input.slice(secondStart , secondEnd +1).trim();
  } else if (firstStart !== -1 && firstEnd !== -1) {
      return input.slice(firstStart , firstEnd+1).trim();
  } else {
      return "";
  }
}
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    setSearchValue(event.target.value);
    setSearchInputValue(event.target.value);
    setSelectedIndex(-1);
    setIsDropdownOpen(true);
    if(event.target.value === ""){
      setSearchList([])
    }
  };
  const handleSearchCount = () => {
    setSearchCount(searchCount+1);
    setSearchPlaceHolder(searchInputValue);
    setSearchInputValue("")
    setSelectedValue(searchInputValue);
  }
  const handleSelectedSearchCount = (item:string) => ()  => {
    setSelectedValue(item);
    setSelectedCountValue(selectedCountValue+1);
    setSearchPlaceHolder(getTextBeforeParenthesis(item));
    setSearchInputValue("")
  }
  const handleKeyDown = (e : React.KeyboardEvent<HTMLInputElement>) => {
    if (!searchList.length) return;
    const totalItems = searchList.length + 1;
    if (e.key === "ArrowDown") {
      setSelectedIndex((prev) => (prev < totalItems - 1 ? prev + 1 : 0));
    } else if (e.key === "ArrowUp") {
      setSelectedIndex((prev) => (prev > 0 ? prev - 1 : totalItems - 1));
    } else if (e.key === "Enter") {
      if (selectedIndex < searchList.length) {
        handleSelectedSearchCount(searchList[selectedIndex])();
      } else if (selectedIndex === searchList.length) {
        handleSearchCount();
      }
    }
  };
  const renderHeader = () => {
    return <>
      <HeaderContainer position="static">
        <HeaderToolbar>
          <HeaderLogo src={navLogo.default} alt="logo" />
          <RightContainer>
            {isLoggedIn && (
              <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                <SearchIcon
                  style={{
                    color: '#94A3B8',
                    position: 'absolute',
                    left: '10px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    fontSize: '20px',
                    zIndex:111
                  }}
                />
                <div style={{ position: 'relative' }}>
                  <input
                    onChange={handleSearchChange}
                    onKeyDown={(e) => handleKeyDown(e)}
                    type="text"
                    placeholder={i18n.t(searchPlaceHolder)}
                    style={{
                      padding: '10px 10px 10px 35px',
                      fontSize: '16px',
                      borderRadius: '20px',
                      border: '1px solid #CBD5E1',
                      width: '330px',
                      height: '44px',
                      color: "#475569",
                    }}
                    className="custom-input"
                    value={searchInputValue}
                  />
                  {loading && <Spinner/>}
                  {searchList.length > 0 && searchInputValue && isDropdownOpen && !isResultEmpty &&(
                    <DropdownContainer sx={{fontFamily:"roboto"}} ref={dropdownRef}>
                       {searchList.map((item, index) => (
                       <DropdownItem
                       key={index}
                       onClick={handleSelectedSearchCount(item)}
                       style={{
                         padding: "20px 0 15px 0",
                         backgroundColor: index === selectedIndex ? '#F1F5F9' : '#FFFFFF',
                         fontWeight: index === selectedIndex ? '600' : 'normal',
                       }}
                       title={getTextBeforeParenthesis(item) + " " + getTextWithParenthesis(item)} // Full text on hover
                     >
                       {(() => {
                         const beforeText = getTextBeforeParenthesis(item);
                         const withText = getTextWithParenthesis(item);
                         const combinedText = beforeText + " " + withText;
                     
                         if (combinedText.length > 44) {
                           const truncatedCombined = combinedText.slice(0, 44) + "...";
                           const truncatedBefore = truncatedCombined.slice(0, beforeText.length);
                           const truncatedWith = truncatedCombined.slice(beforeText.length + 1);
                     
                           return (
                             <>
                               <span style={{ color: '#053348', fontWeight: '500' }}>
                                 {truncatedBefore}
                               </span>
                               {" "}
                               <span style={{ fontWeight: 'normal' }}>
                                 {truncatedWith}
                               </span>
                             </>
                           );
                         }
                     
                         return (
                           <>
                             <span style={{ color: '#053348', fontWeight: '500' }}>
                               {beforeText}
                             </span>
                             {" "}
                             <span style={{ fontWeight: 'normal' }}>
                               {withText}
                             </span>
                           </>
                         );
                       })()}
                     </DropdownItem>
                      ))}
                      <DropdownItem
                        onClick={handleSearchCount}
                        style={{
                          padding: "20px 0 15px 0" ,
                          backgroundColor:selectedIndex === searchList.length ? '#F1F5F9' : '#FFFFFF',
                          fontWeight: '800',
                          color: "#00496B",
                          textDecoration: "underline",
                          }}
                        >
                          {i18n.t("View all results")}...
                      </DropdownItem>
                    </DropdownContainer>
                  )}
                  { isResultEmpty && searchInputValue && isDropdownOpen && searchList.length === 0 && (
                   <DropdownContainer sx={{fontFamily:"roboto"}} ref={dropdownRef}>
                      <DropdownItem
                       style={{
                        backgroundColor:'#FFFFFF',
                        padding: "15px 15px 15px 10px" ,
                        color: "#94A3B8",
                        cursor:"default",
                        textAlign: "center"
                        }}
                        >
                          {i18n.t("Your search didn't match any data.")}
                      </DropdownItem>
                   </DropdownContainer>)
                  }
                </div>

              </div>
            )}
            <CustomSelect
              value={language}
              inputProps={{ 'aria-label': 'Select Language' }}
              renderValue={(selected: any) => {
                const selectedOption = languageOptions.find((option) => option.value === selected);
                return (
                  <ListItem style={{fontWeight:"700"}}>
                    <ListImg src={selectedOption?.image.default} alt={selectedOption?.alt} /> {capitalizeFirstLetter(selectedOption?.value ?? "")}
                  </ListItem>
                );
              }}
              onChange={languageInputHandler}
            >
              {languageOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  <ListItem>
                    <ListImg src={option.image.default} alt={option.alt} /> {option.label}
                  </ListItem>
                </MenuItem>
              ))}
            </CustomSelect>
            {isLoggedIn && (
              <StyledLogoutButtonChanges
                onClick={() => {
                  window.open("https://secure2go.nl/wijzigingen/", "_blank");
                }}
              >
                {i18n.t("Request Changes")}
              </StyledLogoutButtonChanges>
            )}

            {isLoggedIn &&
              <StyledLogoutButton onClick={handleLogoutClick}>
                <LogOutLogo src={logoutIcon.default} />{i18n.t("Logout")}
              </StyledLogoutButton>
            }
          </RightContainer>
        </HeaderToolbar>
      </HeaderContainer >
      <CustomLogoutDialog
        isDialogOpen={isDialogOpen}
        handleCloseDialog={handleCloseDialog}
        handleConfirmLogout={handleConfirmLogout}
        title='Logout Confirmation'
        content='Are you sure you want to log out?'
        confrimButton='Log Out'
      />
    </>

  }
  // Customizable Area End
  function capitalizeFirstLetter(str: string): string {
    return str.toUpperCase();
  }
  function setDefaultLanguage() {
    const selectedLanguage = localStorage.getItem('language') || "en";
    setlanguage(selectedLanguage);
    localStorage.setItem("language", selectedLanguage);
  }
  useEffect(() => {
    setDefaultLanguage()

  }, []);

  return (
    // Customizable Area Start
    <>{renderHeader()}</>
    // Customizable Area End
  )
}

// Customizable Area Start
const HeaderLogo = styled('img')(({ theme }) => ({
  height: '44px',
  width: "191px",
  position: "relative",
  left: "35px",
  [theme.breakpoints.down('sm')]: { // sm corresponds to max-width 600px
    height: '36px',
    width: '150px',
    left: '10px',
  },
}));
const ListItem = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}));

const ListImg = styled('img')(({ theme }) => ({
  height: '18px',
  width: "24px",
  marginRight: '2px',
  [theme.breakpoints.down('sm')]: {
    height: '14px',
    width: '20px',
    marginRight: '0px',
  },
}))

const HeaderContainer = styled(AppBar)(({ theme }) => ({
  backgroundColor: "#FFFFFF",
  display: "flex",
  justifyContent: "space-between",
  height: "76px",
  [theme.breakpoints.down('md')]: {
    height: '60px',
  },
  [theme.breakpoints.down('sm')]: {
    height: '60px',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

const HeaderToolbar = styled(Toolbar)({
  display: "flex", justifyContent: "space-between",
})

const CustomSelect = styled(Select)(({ theme }) => ({
  fontFamily: 'Roboto',
  padding:"0px 14px !important",
  height: "42px",
  width: "116px",
  borderRadius: "8px",
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '24px',
  '& .MuiSelect-select': {
    color: '#053348',
  },
  '& .MuiMenuItem-root': {
    color: '#64748B',
  },
  [theme.breakpoints.down('sm')]: {
    width: "100px",
    height: "38px",
    fontSize: '12px',
  },
}));
const RightContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "20px",
  marginTop: "5px",
  maxHeight: "42px"
})
const LogOutLogo = styled('img')(({ theme }) => ({
  height: '25px',
  width: "25px",
}));
const StyledLogoutButton = styled(Button)(({ theme }) => ({
  color: "#00496B",
  height: "42px",
  width: "126px",
  fontSize: "14px",
  fontFamily: 'Roboto',
  fontWeight: "700",
  border: "1px solid  #00496B",
  textTransform: "none",
  borderRadius: "8px",
  padding: "10px 16px",
}));
const DropdownContainer = styled('div')(({ theme }) => ({

  position: 'absolute',

  top: '50px',

  left: '3px',

  width: '90%',

  backgroundColor: '#FFFFFF',

  border: '1px solid #CBD5E1',

  borderRadius: '8px',

  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',

  zIndex: 1000,

  maxHeight: '300px',

  overflowY: 'auto',
  padding:"0px 15px"

}));



const DropdownItem = styled('div')(({ theme }) => ({

  padding: '10px',

  fontSize: '14px',

  color: '#64748B',

  cursor: 'pointer',

  '&:hover': {

    backgroundColor: '#F1F5F9',

  },

  '&:not(:last-child)': {

    borderBottom: '1px solid #CBD5E1',

  },

}));
const StyledLogoutButtonChanges = styled(Button)(({ theme }) => ({
  color: "white",
  fontSize: "14px",
  fontFamily: 'Roboto',
  fontWeight: "700",
  border: "1px solid  #00496B",
  borderRadius: "8px",
  textTransform:"none",
  padding: "10px 16px",
  backgroundColor: "#00496B",
  '&:hover': {
    backgroundColor: "#00496B",
    color: "#FFFFFF",
  },
}));
const Spinner = styled("div")(({ theme }) => ({
  border: "2px solid #CBD5E1",
  borderTop: "2px solid #00496B",
  borderRadius: "50%",
  width: "16px",
  height: "16px",
  animation: "spin 0.8s linear infinite",
  position: "absolute",
  top: "30%",
  right: "10px",
  transform: "translateY(-50%)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 1,
  [theme.breakpoints.down("sm")]: {
    width: "12px",
    height: "12px",
    borderWidth: "1.5px",
  },
  "@keyframes spin": {
    "0%": { transform: "rotate(0deg)" },
    "100%": { transform: "rotate(360deg)" },
  },
}));

// Customizable Area End