import React from 'react';
import { Navigate } from 'react-router-dom';

export const PrivateRoutes = ({ children }: { children: JSX.Element }) => {
    const isAuthenticated = Boolean(localStorage.getItem('token')); // Replace with actual logic
    return isAuthenticated ? children : <Navigate to="/login" />;
};


export const PublicRoute = ({ children }: { children: JSX.Element }) => {
    const isAuthenticated = Boolean(localStorage.getItem('token'));
    return isAuthenticated ? <Navigate to="/Dashboard" /> : children;
};

export const NewPasswordRoute = ({children} :{children : JSX.Element}) => {
    const isAuthenticated = Boolean(localStorage.getItem('otp'));
    return isAuthenticated ? children : <Navigate to="/login" />;
}
