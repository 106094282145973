import React from "react";
import { styled, keyframes } from "@mui/material/styles";

const spinAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;


const Overlay = styled("div")({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100vw",
  height: "100vh",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1300,
});

const Spinner = styled("div")(() => ({
  width: 40,
  height: 40,
  border: "4px solid #f3f3f3",
  borderTop: `4px solid #00496B`,
  borderRadius: "50%",
  animation: `${spinAnimation} 1s linear infinite`, 
}));

const LoadingSpinner = () => {
  return (
    <Overlay>
      <Spinner />
    </Overlay>
  );
};

export default LoadingSpinner;
