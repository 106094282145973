// Customizable Area Start
import React from "react";
import { Input, Button } from '@builder/component-library';
import { Box, Typography, styled, Checkbox } from "@mui/material";
import VisibilityOffIcons from '@mui/icons-material/VisibilityOff';
import VisibilityIcons from '@mui/icons-material/Visibility';
import CheckIcon from '@mui/icons-material/Check';
import RemoveIcon from "@mui/icons-material/Remove";
import CloseIcon from "@mui/icons-material/Close";
import EmailAccountRegistrationController, {
  Props,
} from "./EmailAccountRegistrationController.web";
import i18n from "../../../components/src/i18nextConfig/i18n";
import { secureLogo } from './assets'
import LoadingSpinner from "../../../components/src/LoadingSpinner";

// Customizable Area End
export default class EmailAccountRegistrationBlock extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  openTermsModal = () => {
    this.setState({ showTerms: true });
  };
  addNewLinesToHTML = (html:any) => {
    const modifiedHTML = html.replace(/<\/(?!a)([^>]+)>/g, '</$1><br/>');
    return modifiedHTML.replace(/<br\/>$/, '');
  };
  
  rendertheDescrption =() =>{
    return(
      <BoxDescrption>
         <CardHeader>
          <TermsHeader>
          <Typography
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '90%',
            }}
          >
            <Logo src={secureLogo.default} style={{ marginRight: 8 }} />
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <Typography
                variant="h5"
                style={{
                  fontWeight: 600,
                  marginBottom: 4,
                }}
              >
                {i18n.t("Terms of Service")}
              </Typography>
              <Typography style={{ color: '#334155', fontWeight: '400', fontFamily:'Roboto', fontSize:'17px' }}>
              {i18n.t("Last updated on")} {this.state.termsCreatedAt}
              </Typography>
            </div>
          </Typography>
          </TermsHeader>
          <CloseButton onClick={() => this.setState({ showTerms: false })}>
            <CloseIcon />
          </CloseButton>
        </CardHeader>
      <TermsContent>
        <Typography
          variant="body1"
          component="div"
          dangerouslySetInnerHTML={{
            __html: this.addNewLinesToHTML(this.state.termsDescription)
          }}
          sx={{
            padding: '0px 20px',
            marginBottom: "0px"
          }}
        />
      </TermsContent>
      <ButtonsWrapperTerms>
          <Button
            data-test-id="btnCancel"
            text={i18n.t("Cancel")}
            onPress={() => this.setState({ showTerms: false })}
            style={{
              backgroundColor: "transparent",
              marginTop: "0px",
              padding: 0,
            }}
            textStyle={{
              color: "#00496B",
              fontSize: 14,
            }}
          />
          <Button
            data-test-id="btnAgreeContinue"
            style={{ backgroundColor: "#00496B" ,maxWidth:"98%" , height:"56px"}}
            text={i18n.t("Agree & Continue")}
            onPress={() => this.setState({ checkedTermsAndConditions: true, showTerms: false })}
          />
        </ButtonsWrapperTerms>
        </BoxDescrption>
    )
  }
  renderActivatePage = () => {
    const { t } = i18n;
    return (
      <FormWrapper>
        <HeadContainer>
          <Header variant="h4">{t("Activate Your Account")}</Header>
          <SubHeader variant="h1">{t(this.activationHeader)}</SubHeader>
        </HeadContainer>
        {this.state.errorStatus && (
          <ShowMessageAlert>
            <MessageText>{t(this.state.errorMessage)}</MessageText>
            <CloseIcon onClick={this.handleCloseErrorMessage} />
          </ShowMessageAlert>
        )}
        <InputWrapper>
          <Label>{t(this.emailLabel)}</Label>
          <CustomInput
            data-test-id="txtInputEmail"
            placeholder={"Email"}
            value={this.state.email}
            disabled
          />
        </InputWrapper>
        <InputWrapper>
          <Label>{t(this.passwordLabel)}</Label>
          <Input
            data-test-id="txtInputPassword"
            secureTextEntry={this.state.enablePasswordField}
            placeholder={t("Your new password")}
            value={this.state.password}
            onChangeText={(text) => this.setPassword(text)}
            rightIcon={
              <Button
                style={{ backgroundColor: "transparent" }}
                data-test-id="btnPasswordShowHide"
                aria-label="toggle password visibility"
                onPress={this.handleClickShowPassword}
                icon={
                  this.state.enablePasswordField ? (
                    <VisibilityIcons style={{ color: "#94A3B8" }} />
                  ) : (
                    <VisibilityOffIcons style={{ color: "#94A3B8" }} />
                  )
                }
              />
            }
          />
        </InputWrapper>

        <CustomCheckbox>
          <Box display="flex" alignItems="center">
            <Checkbox
              checked={this.state.checkedLetter}
              inputProps={{ 'aria-label': 'controlled' }}
              icon={
                this.state.password.length > 0 && !this.state.checkedLetter ? (
                  <RemoveIcon sx={{ color: '#ffffff' }} />
                ) : undefined
              }
              checkedIcon={
                <CheckIcon sx={{ color: '#ffffff', backgroundColor: '#059669', borderRadius: '4px' }} />
              }
              sx={
                this.state.password.length > 0 && !this.state.checkedLetter
                  ? redMinusStyle
                  : {}
              }
            />
            <CheckBoxText>{t(this.passwordCondition1)}</CheckBoxText>
          </Box>
          <Box display="flex" alignItems="center">
            <Checkbox
              data-test-id={"btnRememberMe"}
              style={{ margin: 1 }}
              checked={this.state.checkedLength}
              icon={
                this.state.password.length > 0 && !this.state.checkedLength ? (
                  <RemoveIcon sx={{ color: '#ffffff' }} />
                ) : undefined
              }
              checkedIcon={
                <CheckIcon sx={{ color: '#ffffff', backgroundColor: '#059669', borderRadius: '4px' }} />
              }
              sx={
                this.state.password.length > 0 && !this.state.checkedLength
                  ? redMinusStyle
                  : {}
              }
            />
            <CheckBoxText>{t(this.passwordCondition2)}</CheckBoxText>
          </Box>
          <Box display="flex" alignItems="center">
            <Checkbox
              data-test-id={"btnRememberMe"}
              style={{ margin: 1 }}
              checked={this.state.password.length > 0 && this.state.checkedDigit}
              icon={
                this.state.password.length > 0 && !this.state.checkedDigit ? (
                  <RemoveIcon sx={{ color: '#ffffff' }} />
                ) : undefined
              }
              checkedIcon={
                <CheckIcon sx={{ color: '#ffffff', backgroundColor: '#059669', borderRadius: '4px' }} />
              }
              sx={
                this.state.password.length > 0 && !this.state.checkedDigit
                  ? redMinusStyle
                  : {}
              }
            />
            <CheckBoxText>{t(this.passwordCondition3)}</CheckBoxText>
          </Box>
        </CustomCheckbox>
        <Box display="flex" alignItems="center">
          <Checkbox
            data-test-id={"btnRememberMe"}
            title=""
            style={{ margin: 1 }}
            onChange={this.handleCheckbox}
            checked={this.state.checkedTermsAndConditions}
          />
          <CheckBoxText sx={{ color: "#475569", fontSize: "14px" }}>
            {t(this.agreeWith)}{" "}
            <StyledLink onClick={this.openTermsModal}>
              {t(this.termsAndConditions)}
            </StyledLink>
          </CheckBoxText>
        </Box>
        <ButtonsWrapper>
          <Button
            data-test-id={"btnEmailLogIn"}
            style={{ backgroundColor: "#00496B",position:"relative",right:"10px" ,height:"56px"}}
            text={t(this.btnText)}
            onPress={() => this.createAccount()}
          />
        </ButtonsWrapper>
      </FormWrapper>
    );
  };

  // Customizable Area End
  render() {
    // Customizable Area Start

    return (

      <>
        <Main>
          {this.state.loading && <LoadingSpinner />}
          {this.state.showTerms &&<TermsBody>
            {
            this.rendertheDescrption ()
            }
          </TermsBody>}
          {!this.state.showTerms&&<Body>
              {this.renderActivatePage()}
            </Body>}
        </Main>
      </>

    );
    // Customizable Area Start
  }

}
// Customizable Area Start

const HeadContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "5px",
});

const ShowMessageAlert = styled(Box)({
  margin: "10px 10px",
  background: "#FEE2E2",
  borderLeft: "4px solid #E4022E",
  padding: "12px 30px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderRadius: "4px",
  color: "#E4022E",
  opacity: 1,
  width: "81%",
});

const MessageText = styled(Typography)({
  fontSize: "12px",
  fontWeight: 400,
  textAlign: "left",
});

const redMinusStyle = {
  '& .MuiSvgIcon-root': {
    backgroundColor: '#E4022E',
    borderRadius: '4px',
  },
};

const Main = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  background: "#E9F6FF",
  justifyContent: "center",
  height: "calc(100vh - 120px)",
  fontFamily: "Roboto",
  color: "#053348",
  padding: "20px",
  [theme.breakpoints.down('md')]: {
    padding: "10px",
    height: "auto", 
  },
  [theme.breakpoints.down('sm')]: {
    padding: "10px 5px",
    height: "auto",
  },
}));

const TermsBody = styled(Box)(({ theme }) => ({
  background: "#FFFFFF",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: "10px",
  borderRadius: "10px",
  maxWidth: "1004px",
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    width: "100%",
    padding: "5px",
  },
}));

const Body = styled(Box)(({ theme }) => ({
  background: "#FFFFFF",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: "10px",
  borderRadius: "10px",
  width: "90%",
  maxWidth: "460px",
  transform: 'Scale(0.9)',
  [theme.breakpoints.down('md')]: {
    transform: 'none',
    maxWidth: "90%", 
  },
  [theme.breakpoints.down('sm')]: {
    maxWidth: "100%",
    padding: "10px 15px",
    transform: 'none',
  },
}));

const Logo = styled('img')(({ theme }) => ({
  height: '57px',
  width: "57px",
  [theme.breakpoints.down('sm')]: {
    height: '40px',
    width: "40px",
  },
}));

const Header = styled(Typography)(({ theme }) => ({
  fontSize: "28px",
  fontWeight: 800,
  [theme.breakpoints.down('sm')]: {
    fontSize: "20px",
  },
}));

const SubHeader = styled(Typography)(({ theme }) => ({
  marginTop: "10px",
  fontSize: "18px",
  fontWeight: 500,
  marginBottom: "20px",
  textAlign: "center",
  [theme.breakpoints.down('sm')]: {
    fontSize: "16px",
  },
}));

const FormWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  padding: "0 30px",
  [theme.breakpoints.down('sm')]: {
    padding: "0 20px",
  },
}));

const InputWrapper = styled(Box)({
  paddingBottom: "5px",
});

const CustomInput = styled('input')({
  border: "solid 1px #CBD5E1",
  backgroundColor: "#ededed",
  color: "#94A3B8",
  cursor: "not-allowed",
  width: "100%",
  padding: "12px 10px",
  borderRadius: "8px",
});

const CustomCheckbox = styled(Box)({
  display: "flex",
  flexDirection: "column",
});

const CheckBoxText = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  width: "100%",
  [theme.breakpoints.down('sm')]: {
    fontSize: "10px",
  },
}));

const StyledLink = styled('a')(({ theme }) => ({
  color: '#00496B',
  textDecoration: 'underline',
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'none',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: "12px",
  },
}));

const Label = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontWeight: 500,
  marginBottom: "4px",
  [theme.breakpoints.down('sm')]: {
    fontSize: "14px",
  },
}));

const ButtonsWrapper = styled(Box)(({ theme }) => ({
  flex: 1,
  padding: "8px",
  width: "100%",
  [theme.breakpoints.down('sm')]: {
    padding: "4px",
  },
}));

const ButtonsWrapperTerms = styled(Box)(({ theme }) => ({
  flex: 1,
  padding: "8px",
  width: "100%",
  [theme.breakpoints.down('sm')]: {
    padding: "4px",
    width:"100%"
  },
}));

const CardHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '10px',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(1),
    gap: theme.spacing(1),
  },
}));
const CloseButton = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  backgroundColor: 'transparent',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    width: '30px',
    height: '30px',
    padding: theme.spacing(0.5),
  },

  [theme.breakpoints.up('md')]: {
    width: '40px',
    height: '40px',
  },
}));


const TermsContent = styled(Box)(({ theme }) => ({
  padding: "20px 40px 0px 40px",
  maxWidth: "100%",
  maxHeight: "43vh",
  overflowX: "auto",
  [theme.breakpoints.down('sm')]: {
    padding: "4px",
    width: "100%"
  },
}));

const TermsHeader = styled(Box)(({ theme }) => ({
  marginLeft: "40px",
  [theme.breakpoints.down('sm')]: {
    marginLeft: "20px",
  },
}));
const BoxDescrption = styled(Box)({
maxHeight:'550px' ,
padding:"10px 0px"
});
// Customizable Area End

