import React from "react";
import { styled, keyframes } from "@mui/material/styles";


const spinAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

// Centered spinner wrapper
const LoaderWrapper = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100%",
  position: "relative",
});


const Spinner = styled("div")(() => ({
  width: 40,
  height: 40,
  border: "4px solid #f3f3f3",
  borderTop: `4px solid #00496B`,
  borderRadius: "50%",
  animation: `${spinAnimation} 2s linear infinite`, // Smooth continuous rotation
}));

const ComponentLoader = () => {
  return (
    <LoaderWrapper>
      <Spinner />
    </LoaderWrapper>
  );
};

export default ComponentLoader;
