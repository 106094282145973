import React from "react";

// Customizable Area Start

import { Input, Button } from '@builder/component-library';
import { Box, Typography, styled} from "@mui/material";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import  {
  configJSON
} from "./EmailAccountLoginController";

import { StyleSheet } from "react-native";
import CustomLogoutDialog from "../../../components/src/CustomSessionInactiveLogout.web"
import i18n from "../../../components/src/i18nextConfig/i18n";
import LoadingSpinner from "../../../components/src/LoadingSpinner";

const RemoveBackgroundButton = styled(Button)({
  backgroundColor: "transparent",
  padding: 0, 
  margin: 0,
});

const MainContainer = styled(Box)(({ theme }) => ({
  padding: "20px",
  gap: "50px",
  display: "flex",
  alignItems: "center",
  background: "#E9F6FF",
  justifyContent: "center",
  height: "calc(100vh - 130px)",
  maxWidth: "100%",
  [theme.breakpoints.down('md')]: {
    padding: "15px",
    gap: "30px",
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: "column",
    padding: "10px",
  },
}));

const BodyContainer = styled(Box)(({ theme }) => ({
  background: "#FFFFFF",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px 10px",
  borderRadius: "10px",
  width: "100%",
  maxWidth: "477px",
  [theme.breakpoints.down('md')]: {
    maxWidth: "400px",
    padding: "15px 10px",
  },
  [theme.breakpoints.down('sm')]: {
    maxWidth: "90%",
    borderRadius: "8px",
    padding: "10px",
  },
}));

const Header = styled(Typography)(({ theme }) => ({
  fontFamily: "Roboto",
  fontSize: "28px",
  marginBottom: "15px",
  color: "#053348",
  fontWeight: 800,
  [theme.breakpoints.down('md')]: {
    fontSize: "24px",
    marginBottom: "10px",
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: "20px",
  },
}));

const SubHeader = styled(Typography)(({ theme }) => ({
  fontFamily: "Roboto",
  fontSize: "18px",
  fontWeight: 700,
  color: "#053348",
  [theme.breakpoints.down('md')]: {
    fontSize: "16px",
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: "14px",
  },
}));

const FormWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  marginTop: "20px",
  paddingTop: "10px",
  flexDirection: "column",
  [theme.breakpoints.down('sm')]: {
    marginTop: "15px",
  },
}));

const InputWrapper = styled(Box)(({ theme }) => ({
  flex: 1,
  padding: "10px 20px",
  flexDirection: "column",
  [theme.breakpoints.down('sm')]: {
    padding: "8px 15px",
  },
}));

const ButtonsWrapper = styled(Box)(({ theme }) => ({
  flex: 1,
  padding: "20px",
  marginTop: "80px",
  [theme.breakpoints.down('md')]: {
    marginTop: "50px",
    padding: "15px",
  },
  [theme.breakpoints.down('sm')]: {
    marginTop: "40px",
    padding: "10px",
  },
}));

const InputBox = styled('div')<{ hasError?: boolean }>(({ hasError, theme }) => ({
  border: hasError ? "1px solid red" : "none",
  borderRadius: "10px",
  [theme.breakpoints.down('sm')]: {
    borderRadius: "8px",
  },
}));

const StyledLink = styled('a')(({ theme }) => ({
  color: '#00496B',
  cursor: 'pointer',
  fontFamily: 'Roboto',
  padding: "0 30px",
  fontWeight: 500,
  fontSize: '18px',
  textDecoration: 'none',
  [theme.breakpoints.down('sm')]: {
    fontSize: "16px",
    padding: "0 20px",
  },
}));

const Label = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontWeight: 500,
  fontFamily: "Roboto",
  color: "#053348",
  marginBottom: "5px",
  height: "24px",
  [theme.breakpoints.down('sm')]: {
    fontSize: "14px",
    marginBottom: "4px",
    height: "20px",
  },
}));

const ErrorLabel = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  color: 'red',
  [theme.breakpoints.down('sm')]: {
    fontSize: "11px",
  },
}));


import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController.web";
// Customizable Area End
export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const {t} = i18n;
    return (
      <MainContainer>
        {this.state.loading && <LoadingSpinner/>}
        <BodyContainer>
          <Header variant="h4" >
              { t(this.state.title)}
          </Header>
          <SubHeader variant="h1">
         {t( this.state.loginHeader) }
          </SubHeader>
          <FormWrapper>
            <InputWrapper>
              <Label>{t(this.state.emailLabel) }</Label>
              <InputBox hasError={this.state.emailError}>
                <Input
                  data-test-id="txtInputEmail"
                  value={this.state.email}
                  onChangeText={(text) => this.setEmail(text)}
                  placeholder={ t(this.state.placeHolderEmail)}
                />
                </InputBox>
                { this.state.emailError &&
              <ErrorLabel>{ t(this.state.emailErrorMessage)}</ErrorLabel>}
            </InputWrapper>      
            <InputWrapper>
              <Label>{t( "Password" )}</Label>
              <InputBox hasError={this.state.passwordError}>
                <Input
                  data-test-id="txtInputPassword"
                  secureTextEntry={this.state.enablePasswordField}
                  placeholder={t(this.placeHolderPassword)}
                  value={this.state.password}
                  onChangeText={(text) => this.setPassword(text)}
                  rightIcon={
                    <RemoveBackgroundButton
                    style={{ backgroundColor: "transparent" }}
                      data-test-id="btnTogglePassword"
                      aria-label="toggle password visibility"
                      onPress={this.handleClickShowPassword}
                      icon={this.state.enablePasswordField ? (
                        < VisibilityIcon style={{color: "#94A3B8"}}/>
                      ) : (
                        <VisibilityOffIcon style={{color: "#94A3B8"}}/>
                      )}
                    />
                  }
                />
              </InputBox>
             { this.state.passwordError &&  
              <ErrorLabel>{ t( this.state.passwordErrorMessage ) }</ErrorLabel>}
            </InputWrapper>
            <Box display="flex" justifyContent="flex-end">
            <StyledLink onClick={this.redirectToForgotPage}>{t(this.state.resetPassword)}</StyledLink>
             </Box>
            <ButtonsWrapper sx={{marginLeft:"20pxF"}}>
              <Button
                data-test-id={"btnEmailLogIn"}
                style={{ backgroundColor: "#00496B" ,height:'56px'}}
                onPress={() => this.doEmailLogIn()}
                text={ t(this.state.btnTxtLogin) }
              />
            </ButtonsWrapper>
          </FormWrapper>
        </BodyContainer>
        <CustomLogoutDialog
          isDialogOpen = {this.state.inActiveStatus}
          handleCloseDialog = {this.handleCloseDialog}
          handleConfirmLogout = {this.handleCloseDialog}
          title = {configJSON.sessionInactiveTitle}
          content = {configJSON.sessionInactivecontent}
          confrimButton = {configJSON.sessionInactiveButton}
        />
      </MainContainer>
      
    );
    // Customizable Area End
  }

}
const styles = StyleSheet.create({
  main: {
    display: "flex",
    alignItems: "center"
  },
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
    width: "50%",
  },
  textFont: {
    fontFamily: "Helvetica"
  },
  formWrapper: {
    display: "flex",
    width: "100%",
    paddingTop: "20px" 
  },
  inputWrapper: {
    flex: 1,
    padding: "20px"
  },
  removeBackground: {
    backgroundColor: "transparent" 
  },
  checkBoxText: {
    fontSize: 18,
    color: "#6200EE",
    fontWeight: "bold",
  },
  buttonsWrapper: {
    flex: 1,
    padding: "10px",
  },
  OrWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
  }
});
